import { useEffect } from "react";
import { getBookJson, getCourseTask, validateCourseTask } from "../api";
import Swal from "sweetalert2";
import { useImmer } from "use-immer";
import { useQuery, useMutation } from "react-query";
import { useSearchParams } from "react-router-dom";
import * as Sentry from "@sentry/react";
import axios from "axios";

const GET_COURSE_TASK = "GET_COURSE_TASK";
const STATUS_ERROR_CODES = [401, 404, 405];
const BACKEND_ERROR = [400];

export const useCourseTask = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const taskId = searchParams.get("taskId");
  const token = searchParams.get("token");
  const callback = searchParams.get("callback");
  const isFromMobileApp = searchParams.get("isFromMobileApp");

  const isFromMobileAppSession = JSON.parse(
    sessionStorage.getItem("isFromMobileApp")
  );

  const [enabled, setEnabled] = useImmer(false);
  const [bookUrlenabled, setBookUrlEnabled] = useImmer({ url: null });
  const [validate, setValidate] = useImmer({
    backend_Validation: null,
    isSubmit: false,
  });
  const [bookData, setBookData] = useImmer(undefined);

  const handleTokenError = (e) => {
    const isTokenError = STATUS_ERROR_CODES.some(
      (status) => status === e?.response?.status
    );
    const isBackendError = BACKEND_ERROR.some(
      (status) => status === e?.response?.status
    );
    if (isBackendError) {
      showErrorMessage("Answer submission failed, please retry");
      return true;
    }
    if (isTokenError || e?.response?.status === 500) {
      showErrorMessage("Something went wrong, please retry");
      return true;
    }
    return false;
  };

  const handleError = (data) => {
    if (handleTokenError(data)) return;
    if (data?.response?.status !== 400 && data?.response?.status !== 401) {
      Sentry.captureException(data);
    }
  };

  const showErrorMessage = (text) => {
    Swal.fire({
      text,
      confirmButtonColor: "#ae96d7",
      confirmButtonText: "OK",
      allowOutsideClick: false,
      allowEscapeKey: false,
    }).then((result) => {
      if (result.isConfirmed) {
        window.close();
      }
    });
  };

  const useCourseTaskQuery = useQuery(
    [GET_COURSE_TASK, taskId],
    () =>
      getCourseTask(
        { taskId: sessionStorage.getItem("taskId") },
        {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        }
      ),
    {
      enabled,
      onSettled: (e) => {
        handleTokenError(e);
        setEnabled(false);
      },
    }
  );

  const useBookQuery = useMutation(getBookJson, {
    onSuccess: setBookData,
    onError: handleError,
    onSettled: () => {},
  });

  const useTaskValidate = useMutation(validateCourseTask, {
    onSuccess: () => {
      if (validate.isSubmit) {
        console.log("success");
        window.close();
        if (isFromMobileAppSession) {
          setSearchParams({ isTaskCompletedFromMobileApp: true });
        }
      }
    },
    onError: handleError,
    onSettled: () => {},
  });

  useEffect(() => {
    if (validate.backend_Validation !== null) {
      useTaskValidate.mutate({
        validate: { ...validate, isSubmit: undefined },
        taskId: sessionStorage.getItem("taskId"),
        isSubmit: validate.isSubmit,
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      });
    }
  }, [validate.backend_Validation]);

  useEffect(() => {
    if (bookUrlenabled.url !== null) {
      useBookQuery.mutate({ url: bookUrlenabled.url });
    }
  }, [bookUrlenabled.url]);

  useEffect(() => {
    if (taskId && token) {
      sessionStorage.setItem("taskId", taskId);
      sessionStorage.setItem("token", token);
      sessionStorage.setItem("callback", callback);
      sessionStorage.setItem("isFromMobileApp", isFromMobileApp ? true : false);
      axios.defaults.baseURL = `${sessionStorage.getItem("callback")}/api/`;
      setEnabled(true);
    }
  }, [taskId, token, callback]);

  const { data, isError, isLoading } = useCourseTaskQuery;
  const { data: validateResponse, isLoading: validateLoading } =
    useTaskValidate;
  const { isLoading: bookLoading } = useBookQuery;

  return {
    data,
    isError,
    isLoading,
    setValidate,
    setBookUrlEnabled,
    bookData,
    validateResponse,
    validateLoading,
    bookLoading,
  };
};
