
import { useOpenBookScopeContext } from "../../../master";
import backArrow from "../../../assets/img/back-arrow.png";
import { useImmer } from "use-immer";
export const SearchResult = () => {
    const { openBookState, setOpenBookState } = useOpenBookScopeContext();
    const [viewMoreIndex, setViewMoreIndex] = useImmer({
        index: undefined
    });

    const gotoResult = (searchResult) => {
        const { chapterIndex, searchKeyWord } = searchResult;
        setOpenBookState((draft) => {
            draft.chapterIndex = chapterIndex;
            draft.scrollToWord = searchKeyWord;
            draft.isChapterSelected = true;
            draft.searchResults = undefined;
        });
    }

    const loadResults = (index) => {
        setViewMoreIndex({ index: index });
    }

    const searchResultsBack = () => {
        if (viewMoreIndex.index !== undefined) {
            setViewMoreIndex({ index: undefined });
        } else {
            setOpenBookState((draft) => {
                draft.searchResults = undefined;
                draft.scrollToWord = undefined;
            });
        }
    }
    const { searchResults } = openBookState;
    return (
        <>   
            {searchResults && <>
                <div><img src={backArrow} alt="back arrow" className="back-arrow" onClick={searchResultsBack} /></div>
                {searchResults.length === 0 && <div className="search-results">No Result found!!</div>}
                {searchResults.length > 0 && <>
                    {viewMoreIndex.index === undefined && searchResults.map((searchResult, chapterIndex) => {
                        return (
                            <div className="result-item" key={chapterIndex}>
                                <h6 className="chapter-number">{searchResult.chapterName}</h6>
                                {searchResult.results.slice(0, 2).map((result, index) => {
                                    return (
                                        <div key={index}>
                                            <div className="chapter-details search-result" onClick={() => { gotoResult({ chapterIndex: searchResult.chapterIndex, searchKeyWord: result }) }}>
                                                {result.split(" ").map((word, index) => {
                                                    if (word.toLowerCase().includes(searchResult.searchKeyWord.toLowerCase())) {
                                                        return <span key={index} className="search-word">{word} </span>
                                                    }
                                                    return <span key={index}>{word} </span>
                                                })}
                                            </div>
                                            {index === 0 && searchResult.results.length > index + 1 && <hr className="search-item-hr" />}
                                        </div>
                                    )
                                })}
                                {searchResult.results.length > 2 && <>
                                    <button className="more-results" onClick={() => { loadResults(chapterIndex) }}>More Results</button>
                                </>}
                                <hr className="search-item-hr-last" />
                            </div>
                        )
                    })}
                    {viewMoreIndex.index !== undefined && searchResults.map((searchResult, index) => {
                        if (index === viewMoreIndex.index) {
                            return (
                                <div className="result-item" key={index}>
                                    <h6 className="chapter-number">{searchResult.chapterName}</h6>
                                    {searchResult.results.map((result, index) => {
                                        return (
                                            <div key={index}>

                                                <div className="chapter-details search-result" onClick={() => { gotoResult({ chapterIndex: searchResult.chapterIndex, searchKeyWord: result }) }}>
                                                    {result.split(" ").map((word, index) => {
                                                        if (word.toLowerCase().includes(searchResult.searchKeyWord.toLowerCase())) {
                                                            return <span key={index} className="search-word">{word} </span>
                                                        }
                                                        return <span key={index}>{word} </span>
                                                    })}
                                                </div>
                                                {searchResult.results.length > index + 1 && <hr className="search-item-hr" />}
                                            </div>
                                        )
                                    })}
                                </div>
                            )
                        }
                    })}
                </>
                }
            </>}
        </>
    );
}