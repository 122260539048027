import parse from "html-react-parser";
import { useImmer } from "use-immer";
import { useOpenBookScopeContext } from "../../../master";
import { useEffect, useRef } from "react";
import { ErrorMsgDialog } from "./ErrorMsg";
import backArrow from "../../../assets/img/back-arrow.png";
import { SearchResult } from "./SearchResult";
import appConfig from "../../../appConfig.json";
import { getPlainText, getText } from "../utils/bookUtils";

export const Book = () => {
    const { openBookState, setOpenBookState } = useOpenBookScopeContext();
    const bookData = openBookState?.bookData;
    const chapters = bookData?.chapters;
    const chapterList = bookData?.chapters?.map(chapter => chapter.chapterName);
    const contentRef = useRef();
    const [openErrMsgDialog, setOpenErrMsgDialog] = useImmer({
        open: false
    });

    useEffect(() => {
        if (openBookState.isChapterSelected && openBookState.chapterIndex !== undefined) {
            loadChapter(openBookState.chapterIndex);
            setTimeout(scrollNow, 1000);
        }
    }, [openBookState.chapterIndex]);

    const scrollNow = () => {
        if (openBookState.scrollToWord) {
            contentRef.current?.querySelector(`.scroll-to-word`)?.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
        } else {
            document.querySelector(`.chapter-details`).scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
        }
        setTimeout(() => {
            const allOccurrences = document.querySelectorAll('.scroll-to-word');

            allOccurrences.forEach((element) => {
                element.classList.remove('scroll-to-word');
            });
            setOpenBookState((draft) => {
                draft.scrollToWord = undefined;
            });
        }, 1500);
    }

    useEffect(() => {
        if (openBookState.isChapterSelected) {
            loadChapter(openBookState.chapterIndex);
        }
    }, [openBookState.answers]);

    const toggleErrorMsg = () => {
        setOpenErrMsgDialog((draft) => {
            draft.open = !draft.open;
        });
    }

    const checkAnswerContent = (answers, item) => {
        if(appConfig.selectableTypes.includes(item.type)){
            return answers.some(ans => ans.text === parse(getPlainText(item.text)));
        }
        return false;
    }

    const loadChapter = (index) => {
        setOpenBookState((draft) => {
            draft.chapterIndex = index;
            draft.isChapterSelected = true;
            const chapter = chapters[index];
            draft.selectedChapter.chapterHead = chapter.chapterName;
            draft.selectedChapter.chapterDetails = chapter.content.map((item, sIndex) => {
                let classList = checkAnswerContent(openBookState.answers, item) ? "sentence sentence-selected" : "sentence";
                if (
                    openBookState.scrollToWord
                    && appConfig.searchableTypes.includes(item.type)
                    && getText(item).toLowerCase().includes(openBookState.scrollToWord.toLowerCase())) {
                    classList += " scroll-to-word";
                }
                if (appConfig.selectableTypes.includes(item.type)) {
                    classList += " sentence-selectable";
                }

                if (item.type === "paragraph") {
                    return <p key={sIndex} id={"sentence" + index + sIndex} className={classList} onClick={(event) => { handleSelect(item.text, "sentence" + index + sIndex) }}>{parse(item.text)}</p>;
                }
                if (item.type === "heading") {
                    return <h3 key={sIndex} className={`heading-content ${classList}`}>{parse(item.text)}</h3>;
                }
                if (item.type === "list") {
                    return <ul className="list-content" key={sIndex}>
                        {item.items.map((i, lindex) => {
                            const classListLi = classList + (checkAnswerContent(openBookState.answers, {...i, type: "list"}) ? " sentence-selected" : "");
                            return <li key={index + sIndex + lindex} id={"sentence" + index + sIndex + lindex} className={classListLi} onClick={(event) => { handleSelect(i.text, "sentence" + index + sIndex + lindex) }}>{parse(i.text)}</li>;
                        })}
                    </ul>;
                }
                if (item.type === "text") {
                    return <em key={sIndex} className={classList} id={"sentence" + index + sIndex} onClick={(event) => { handleSelect(item.text, "sentence" + index + sIndex) }}>{parse(item.text)}</em>;
                }
                if (item.type === "bold") {
                    return <strong key={sIndex} className={classList} id={"sentence" + index + sIndex} onClick={(event) => { handleSelect(item.text, "sentence" + index + sIndex) }}>{parse(item.text)}</strong>;
                }
                if (item.type === "table") {
                    return <div className="table-content" key={sIndex}>
                        <table>
                            <thead>
                                <tr>
                                    {item.headers.map((header, index) => <th key={index}>{parse(header)}</th>)}
                                </tr>
                            </thead>
                            <tbody>
                                {item.rows.map((row, index1) => <tr key={index1}>
                                    {row.map((col, index) => <td key={index}>{parse(col)}</td>)}
                                </tr>)}
                            </tbody>
                        </table>
                    </div>;
                }
                return "";
            });
            draft.selectedChapter.chapterNo = index + 1;
        });
    }

    const handleSelect = (sentence, id) => {
        const classList = document.getElementById(id).classList;
        if (!classList.contains('sentence-selected')) {
            if (getCharCount() + sentence.length > openBookState.maxCharLimit) {
                toggleErrorMsg();
                return;
            }
            classList.add('sentence-selected');
            sentence = parse(getPlainText(sentence));
            setOpenBookState((draft) => {
                draft.answers.push({
                    id: generateUniqueId(),
                    text: sentence
                });
                draft.scrollToWord = undefined;
            });
        }
    }

    function generateUniqueId() {
        const timestamp = Date.now().toString(36);
        const randomPart = Math.random().toString(36).substr(2);
        return timestamp + randomPart;
    }

    const getCharCount = () => {
        return openBookState.answers.map(ans => ans.text).reduce((total, currentString) => {
            return total + currentString.length;
        }, 0);
    }

    const goHome = () => {
        setOpenBookState((draft) => {
            draft.isChapterSelected = false;
            draft.chapterIndex = 0;
            draft.scrollToWord = undefined;
        });
    }

    const chapterData = openBookState.selectedChapter;

    return (
        <>
            {openBookState.bookLoading && <div className="spinner-container"><div className="spinner-border" role="status"></div></div>}
            {!openBookState.bookLoading && <div className="book-container">
                {!openBookState.isChapterSelected && !openBookState.searchResults && <>
                    <h1 className="chapters-head">Chapters</h1>
                    <div className="chapter-list">
                        <ul>
                            {chapterList?.map((chapter, index) => {
                                return (
                                    <div key={index}>
                                        <li onClick={() => { loadChapter(index) }} >{index + 1}. {chapter}</li>
                                        <hr />
                                    </div>
                                );
                            })
                            }

                        </ul>
                    </div>
                </>}
                {openBookState.isChapterSelected && !openBookState.searchResults && <>
                    <div><img src={backArrow} alt="back arrow" className="back-arrow" onClick={goHome} /></div>
                    <h6 className="chapter-number">Chapter {chapterData.chapterNo}</h6>
                    <h1 className="chapters-head">{chapterData.chapterHead}</h1>
                    <div className="chapter-details" ref={contentRef}>
                        {chapterData.chapterDetails}
                    </div>
                </>}
                <SearchResult />
            </div>}
            {openErrMsgDialog.open && <ErrorMsgDialog open={openErrMsgDialog.open} toggleErrorMsg={toggleErrorMsg} />}
        </>
    );
}



